<template>
  <div>
    <div class="frmBlock__layout">
      <ImagePicker
        ref="imagePicker3"
        :index="option.componentOptionId"
        :value="img"
        @updateImg="v => updateOption('img', v)"
      />
    </div>
    <div class="frmBlock__title frmBlock__title--last">이미지 설명(alt)</div>
    <div class="frmBlock__layout">
      <TextField
        placeholder="이미지 설명을 입력해주세요."
        :value="alt"
        @input="v => updateOption('alt', v)"
      />
    </div>
    <div class="frmBlock__title frmBlock__title--last">
      URL
      <tooltip
        top="30px"
        :text="`도메인이 포함된 URL을 입력하는 경우 https:// 또는 http:// 를 함께 입력해주세요.<br/>
        ･ 예) https://qpicki.com`"
      />
    </div>
    <div class="frmBlock__layout frmBlock__layout--grow">
      <TextField
        placeholder="URL을 입력해주세요."
        :value="url"
        @input="v => updateOption('url', v)"
      />
      <Dropdown
        :value="target"
        @updateTarget="v => updateOption('target', v)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    option: {
      type: Object
    }
  },
  data() {
    return {
      img: "",
      alt: "",
      url: "",
      target: ""
    };
  },
  computed: {
    initialValue() {
      return this.$route.path === "/option"
        ? this.option.componentOption.properties
        : this.option.value;
    }
  },
  methods: {
    // 프로퍼티 업데이트 (파트너)
    updateProperty(img, alt, url, target) {
      let modified = {
        id: this.option.componentOption.id,
        properties: [
          {
            id: img.id,
            key: img.key,
            initialValue: img.value
          },
          {
            id: alt.id,
            key: alt.key,
            initialValue: alt.value
          },
          {
            id: url.id,
            key: url.key,
            initialValue: url.value
          },
          {
            id: target.id,
            key: target.key,
            initialValue: target.value
          }
        ]
      };
      this.$emit("updateProperty", this.option.componentOption.key, modified);
    },
    // 옵션 Update
    updateOption(type, value) {
      switch (type) {
        case "img":
          this.img = value;
          break;

        case "alt":
          this.alt = value;
          break;

        case "url":
          this.url = value;
          break;

        case "target":
          this.target = value;
          break;
      }
      this.$emit("updateOption", this.option.componentOption.key, type, value);

      if (this.$route.path === "/option") {
        let mProperties = this.option.modified.properties;
        let obj = {};

        mProperties.forEach(item => {
          obj[item.key] = {
            id: item.id,
            key: item.key,
            value: type === item.key ? value : item.initialValue
          };
        });

        this.updateProperty(obj.img, obj.alt, obj.url, obj.target);
      }
    },
    //값 추출
    extractValue() {
      let isOption = this.$route.path === "/option" ? true : false;
      if (!isOption) {
        this.img = this.option.value.img;
        this.alt = this.option.value.alt;
        this.url = this.option.value.url;
        this.target = this.option.value.target;
      } else {
        let obj = {};

        this.initialValue.forEach(item => {
          obj[item.key] = {
            id: item.id,
            key: item.key,
            value: item.initialValue
          };
          switch (item.key) {
            case "img":
              this.img = item.initialValue;
              break;

            case "alt":
              this.alt = item.initialValue;
              break;

            case "url":
              this.url = item.initialValue;
              break;

            case "target":
              this.target = item.initialValue ?? "_self";
              break;
          }
        });

        this.updateProperty(obj.img, obj.alt, obj.url, obj.target);
      }
    }
  },
  mounted() {
    // localStorage.setItem(`imgOptId${this.option.id}`, this.option.value.img);
  },
  created() {
    // updateImage 구독
    this.$EventBus.$on(`updateImage${this.option.componentOptionId}`, param => {
      // localStorage.setItem(`imgOptId${this.option.id}`, param.value);
      if (param.type === "change" && this.$refs.imagePicker3) {
        this.$refs.imagePicker3.imageRead(param.value);
      }
      this.updateOption("img", param.value);
    });

    this.extractValue();
  }
};
</script>

<style lang="scss" scoped></style>
